import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import LinkUrl from '../LinkUrl';
import './styles.css';
import { trustPilotScriptTimeout } from '../../configs';

export default function Sidebar({ destination }) {
  const [trustpilotBgShow, setTrustpilotBgShow] = useState(true);

  useEffect(() => {
    const TrustpilotBgShowTimeout = setTimeout(() => {
      setTrustpilotBgShow(false);
    }, trustPilotScriptTimeout);
    return () => {
      clearTimeout(TrustpilotBgShowTimeout);
    };
  }, []);

  return (
    <div className="info-country">
      <div className="city-block">
        <div className="content-block">
          <div
            className="trustpilot-widget"
            data-locale="en-US"
            data-template-id="539ad60defb9600b94d7df2c"
            data-businessunit-id="51c1ebb90000640005477b45"
            data-style-height="100%"
            data-style-width="100%"
            data-theme="light"
            data-stars="5"
          >
            {trustpilotBgShow && <div className="trustpilot-bg" />}
          </div>
        </div>
        <div className="content-block">
          <div className="booking-wrapper hotel-booking d-flex">
            <p>Find a hotel in {destination}</p>
            <a
              href="https://www.booking.com/?aid=870169"
              target="_blank"
              className="o-button button-book"
              rel="noopener"
            >
              See our deals &gt;
            </a>
          </div>
        </div>
        <div className="content-block">
          <div className="booking-wrapper cars-booking d-flex">
            <p>Car rental in {destination} starting from $34</p>
            <LinkUrl path="/cars" className="o-button button-book">
              Book now &gt;
            </LinkUrl>
          </div>
        </div>
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  destination: PropTypes.string.isRequired
};
