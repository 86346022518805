import PropTypes from 'prop-types';
import React from 'react';
import { getUrlParams } from '../../helpers/urlParams';
import { setCityPairDates } from '../../helpers/dateHelper';
import './styles.css';

import Card from './FlightCard';
import { getSearchFlightURL } from '../../helpers/utils';
import { baseIBEURL } from '../../configs';
import { PHONE_NUMBER } from '../../constants/phoneNumber';

const monthMap = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

const parseCityParams = card => {
  const {
    airlineLogo,
    airlineName,
    destination,
    destinationIATA,
    origin,
    originIATA,
    price,
    endDate,
    startDate
  } = card;

  let { newStartDate, newEndDate } = setCityPairDates(startDate, endDate);

  return {
    airlineLogo,
    airlineName,
    destination,
    destinationIATA,
    origin,
    originIATA,
    price,
    startDate: `${monthMap[newStartDate.getMonth()]} ${newStartDate.getDate()}`,
    endDate: `${monthMap[newEndDate.getMonth()]} ${newEndDate.getDate()}`
  };
};

const campaign = getUrlParams().campaign;

const FlightCardsList = ({
  city: { pairs, tag, flightsCardTitle, showDates, globalShowDates },
  title,
  isFromTo
}) => {
  const onSearch = params => {
    let { newStartDate, newEndDate } = setCityPairDates(
      params.startDate,
      params.endDate
    );
    params.startDate = newStartDate;
    params.endDate = newEndDate;

    window.open(getSearchFlightURL(params, '_blank'));
  };

  let flightsCardSubTitle = flightsCardTitle &&
    flightsCardTitle.length && [...flightsCardTitle.slice(1)];

  return (
    <div className="cheap-flight-deal-list">
      <div className="cheapest-flight">
        <>
          {flightsCardTitle && flightsCardTitle.length ? (
            <>
              <p className="cheapest-flight-city cheapest-flight-city-business-page">
                {flightsCardTitle[0]}
              </p>
              <br />

              {flightsCardSubTitle.map(subTitle => (
                <>
                  <span className="flightCardSubTitle">{subTitle}</span>
                  <br />
                </>
              ))}
            </>
          ) : (
            <h2 className="cheapest-flight-city">{title}</h2>
          )}
        </>
      </div>
      {pairs.map(
        (card, i) =>
          card.price && (
            <Card
              key={`${card.origin}_${i}`}
              {...parseCityParams(card)}
              isFromTo={isFromTo}
              onSearch={() => {
                onSearch({
                  origin: card.originIATA,
                  destination: card.destinationIATA,
                  endDate: new Date(card.endDate),
                  startDate: new Date(card.startDate),
                  cabinClass:
                    tag && tag.category === 'business-class'
                      ? 'BUSINESS'
                      : 'ECONOMY',
                  passengers: {
                    adults: 1
                  },
                  phoneNumber:
                    tag && tag.fixedNumber && PHONE_NUMBER[tag.continent],
                  baseIBEURL,
                  campaign,
                  activeTabIndex: 0
                });
              }}
              showDates={showDates}
              globalShowDates={globalShowDates}
            />
          )
      )}
    </div>
  );
};

export default FlightCardsList;

FlightCardsList.propTypes = {
  destination: PropTypes.string,
  isFromTo: PropTypes.bool,
  title: PropTypes.string.isRequired
};

FlightCardsList.defaultProps = {
  destination: 'Atlanta',
  isFromTo: false
};
