import React from 'react';

import LinkUrl from '../LinkUrl';

const Crumb = ({ link, label, isLast }) => (
  <li className="c-breadcrumbs__item">
    <LinkUrl
      path={`/${link}`}
      className={`c-breadcrumbs__link ${
        isLast ? 'c-breadcrumbs__link-down' : ''
      }`}
    >
      {label}
    </LinkUrl>
    {!isLast && <span className="arrow-right-icon" />}
  </li>
);

export default Crumb;
