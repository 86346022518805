import PropTypes from 'prop-types';
import React from 'react';
import { formatPhone } from '../../helpers/callTracking';

import './styles.css';

const CallUs = ({ phoneNumber }) => (
  <div className="call-us-wrapper d-flex">
    <a
      href={formatPhone(phoneNumber, 'callAction')}
      id="call-us-phone-city-pairs-icon"
    >
      <div className="call-us-icon d-flex dial-icon" />
    </a>
    <div className="call-us-info">
      <p>Call us now for all unpublished fares</p>
      <a
        className="phone-number"
        href={formatPhone(phoneNumber, 'callAction')}
        id="call-us-phone-city-pairs"
      >
        {formatPhone(phoneNumber)}
      </a>
    </div>
  </div>
);

export default CallUs;

CallUs.propTypes = {
  phoneNumber: PropTypes.string.isRequired
};
