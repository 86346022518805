import React, { useState, useEffect } from 'react';
import './index.css';
import { trustPilotScriptTimeout } from '../../configs';

const Trustpilot = () => {
  const [trustpilotBgShow, setTrustpilotBgShow] = useState(true);

  useEffect(() => {
    const TrustpilotBgShowTimeout = setTimeout(() => {
      setTrustpilotBgShow(false);
    }, trustPilotScriptTimeout);
    return () => {
      clearTimeout(TrustpilotBgShowTimeout);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="trustpilot-home-block">
        <div className="title">
          <h3>We Care About Our Customers</h3>
        </div>
        <div className="trustpilot-wrapper">
          <div className="hide-link-tp-home"></div>
          <div
            className="trustpilot-widget is-desktop"
            data-locale="en-US"
            data-template-id="53aa8912dec7e10d38f59f36"
            data-businessunit-id="51c1ebb90000640005477b45"
            data-style-height="130px"
            data-style-width="100%"
            data-theme="light"
            data-stars="5"
          >
            {trustpilotBgShow && <div className="trustpilot-bg" />}
          </div>
          <div
            className="trustpilot-widget is-mobile"
            data-locale="en-US"
            data-template-id="539ad0ffdec7e10e686debd7"
            data-businessunit-id="51c1ebb90000640005477b45"
            data-style-height="350px"
            data-style-width="100%"
            data-theme="light"
            data-stars="5"
          >
            {trustpilotBgShow && <div className="trustpilot-bg" />}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Trustpilot;
