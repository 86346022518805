import { baseApiUrl } from '../configs';

export default async function configurePageData(slug) {
  try {
    let newSlug = '/' + slug;

    const fetchConfiguredPageData = await fetch(
      `${baseApiUrl}/landing/pageData?slug=${newSlug}`
    );
    const configuredPageDataJson = await fetchConfiguredPageData.json();

    if (configuredPageDataJson && configuredPageDataJson.results) {
      configuredPageDataJson.results.path = JSON.parse(
        configuredPageDataJson.results.path
      );
      configuredPageDataJson.results.pairs =
        configuredPageDataJson.results.cityPairs;
      delete configuredPageDataJson.results.cityPairs;
    }

    if (configuredPageDataJson && configuredPageDataJson.results) {
      let data;
      if (
        Array.isArray(configuredPageDataJson.results.pairs) &&
        configuredPageDataJson.results.pairs.length > 0
      ) {
        data = {
          pairs: configuredPageDataJson.results.pairs,
          fixedNumber: configuredPageDataJson.results.fixedNumber,
          showDates: configuredPageDataJson.results.showDates,
          globalShowDates: configuredPageDataJson.results.globalShowDates,
          title: configuredPageDataJson.results.title
        };
      } else {
        data = {
          fixedNumber: configuredPageDataJson.results.fixedNumber,
          showDates: configuredPageDataJson.results.showDates,
          globalShowDates: configuredPageDataJson.results.globalShowDates,
          title: configuredPageDataJson.results.title
        };
      }
      return data;
    }
    // If configuredPageDataJson or configuredPageDataJson.results is falsy, return null
    return null;
  } catch (error) {
    console.error('An error occurred:', error);
    // If an error occurs, re-throw it to be caught by the caller
    throw error;
  }
}
