import * as PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';

import { capitalize } from '../helpers/utils';
import CityDetail from '../components/city-detail';
import Sidebar from '../components/Sidebar/sidebar';
import FlightCardsList from '../components/FlightCards/FlightCardsList';
import CallUs from '../components/CallUs/CallUs';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';

import Layout from '../components/layout';
import SubscribeForm from '../components/SubscribeForm';
import Trustpilot from '../components/Trustpilot';
import SEO from '../components/seo';
import OtherDestinations from '../components/OtherDestinations/OtherDestinations';
import { isFlykart } from '../helpers/urlParams';
import { callTrackingApiUrl } from '../configs';
import configurePageData from '../helpers/configurePageData';

class CityTemplate extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      fromToCityJson: PropTypes.object.isRequired,
      allMarkdownRemark: PropTypes.object,
      aImage: PropTypes.object,
      fImage: PropTypes.object
    })
  };

  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: '',
      isBannersShown: false,
      isBannerApiResponseReceived: false,
      ipAction: '',
      configuredData: null
    };
  }

  async componentDidMount() {
    const dataConfig = await configurePageData(
      this.props.data.fromToCityJson.slug
    );
    const response = await fetch(`${callTrackingApiUrl}/call-tracking/banner`);
    const responseJson = await response.json();

    this.setState({
      isBannersShown: responseJson.data.show,
      isBannerApiResponseReceived: true,
      ipAction: responseJson.data.ipAction
    });

    if (dataConfig) {
      this.setState({
        configuredData: dataConfig
      });
    }
  }

  render() {
    const {
      fromToCityJson: fromToCityJSON,
      allMarkdownRemark
    } = this.props.data;
    const fromToCityJson = {
      ...fromToCityJSON,
      ...(this.state.configuredData && this.state.configuredData)
    };
    const {
      phoneNumber,
      isBannersShown,
      isBannerApiResponseReceived,
      ipAction
    } = this.state;
    const path = [
      ...fromToCityJson.path,
      { name: fromToCityJson.destination, slug: fromToCityJson.slug }
    ];
    const slug = fromToCityJson.slug;
    const countryName =
      fromToCityJson.path.length >= 1
        ? capitalize(fromToCityJson.path[fromToCityJson.path.length - 1].name)
        : '';
    const title = `Flights to ${capitalize(fromToCityJson.title)} (${
      fromToCityJson.pairs[0].destinationIATA
    }), ${countryName} from $${fromToCityJson.pairs[0].price}`;

    const { origin, destination } = fromToCityJson.pairs[0];

    const layoutTitle = `Find Cheap Flights from ${origin} to ${destination}`;
    const flightCardsListTitle = `Cheap flights from ${origin} to ${destination} from $${fromToCityJson.pairs[0].price}`;
    const flykartTheme = isFlykart();
    const img = flykartTheme
      ? this.props.data.fImage.childImageSharp.fluid
      : this.props.data.aImage.childImageSharp.fluid;
    return (
      <Layout
        pageData={fromToCityJSON}
        passPhoneNumber={phoneNumber => {
          this.setState({
            phoneNumber
          });
        }}
        title={layoutTitle}
        image={img}
        isBannersShown={isBannersShown}
        isBannerApiResponseReceived={isBannerApiResponseReceived}
        ipAction={ipAction}
      >
        <SEO title={title} slug={slug} />
        <div className="main">
          <Breadcrumbs path={path} />
          <div className="page-content">
            <div className="flight-content">
              <FlightCardsList
                city={fromToCityJson}
                title={flightCardsListTitle}
                cheapestPrice={fromToCityJson.pairs[0].price}
              />
              {isBannersShown && <CallUs phoneNumber={phoneNumber} />}
              <CityDetail
                city={fromToCityJson}
                htmlContent={
                  allMarkdownRemark.edges[0]
                    ? allMarkdownRemark.edges[0].node.html
                    : null
                }
              />
              {fromToCityJson.originNearest && (
                <OtherDestinations
                  data={fromToCityJson.originNearest}
                  text={`Flights to ${capitalize(
                    fromToCityJson.destination
                  )} from nearby airports `}
                />
              )}
              {fromToCityJson.destinationNearest && (
                <OtherDestinations
                  data={fromToCityJson.destinationNearest}
                  text={`Flights to nearby to ${capitalize(
                    fromToCityJson.destination
                  )} airports `}
                />
              )}
            </div>
            <Sidebar destination={capitalize(fromToCityJson.title)} />
          </div>
          <SubscribeForm isInnerPage />
        </div>
        <Trustpilot />
      </Layout>
    );
  }
}

export default CityTemplate;

export const pageQuery = graphql`
  query($title: String!, $image: String) {
    fromToCityJson(title: { eq: $title }) {
      ...FromToCity_details
    }
    allMarkdownRemark(filter: { frontmatter: { page: { eq: $title } } }) {
      edges {
        node {
          html
          frontmatter {
            title
            date
          }
        }
      }
    }
    aImage: file(relativePath: { in: [$image, "flight-background.jpg"] }) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    fImage: file(relativePath: { in: [$image, "flykart-img/flykart-bg.jpg"] }) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
