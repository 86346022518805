import React from 'react';

import Crumb from './Crumb';

import { capitalize } from '../../helpers/utils';

import './styles.css';

const Breadcrumbs = ({ path }) => {
  const domain = path[0].slug.includes('canada') ? './canada' : '.';
  return (
    <ul className="c-breadcrumbs__items d-flex">
      <Crumb link={domain} label="Home" />
      <Crumb link={`${domain}/flights`} label="Flights" />
      {path.map(({ name, slug }, i) => (
        <Crumb
          link={
            slug.includes('usa') ? slug.replace('usa', 'north-america') : slug
          }
          label={capitalize(name)}
          isLast={i === path.length - 1}
          key={name}
        />
      ))}
    </ul>
  );
};

export default Breadcrumbs;
