import PropTypes from 'prop-types';
import React from 'react';

import './styles.css';

import { capitalize } from '../../helpers/utils';
import LinkUrl from '../LinkUrl';

const OtherDestination = ({ text, data }) => (
  <div className="other-destinetion-block">
    <h3>{text}</h3>
    <div className="column">
      {data.map(({ slug, title }) => (
        <LinkUrl
          path={`/${slug}`}
          className="other-destination-city"
          key={title}
        >
          {capitalize(title)}
        </LinkUrl>
      ))}
    </div>
  </div>
);

export default OtherDestination;

OtherDestination.propTypes = {
  data: PropTypes.array.isRequired,
  text: PropTypes.string.isRequired
};
