import PropTypes from 'prop-types';
import React from 'react';
import Image from '../image';
import { useWindowSize } from '../../helpers/useWindowSize';
import { getCurrency } from '../../helpers/currency';

const FlightCard = ({
  airlineLogo,
  airlineName,
  destination,
  destinationIATA,
  origin,
  originIATA,
  price,
  endDate,
  startDate,
  onSearch,
  isFromTo,
  showDates,
  globalShowDates
}) => {
  const [width] = useWindowSize();
  let isDatesVisible;
  if (globalShowDates) {
    isDatesVisible = true;
  } else {
    showDates ? (isDatesVisible = true) : (isDatesVisible = false);
  }
  return (
    <div className="cheap-flight-deal-card d-flex">
      <div className="d-flex">
        <div className="logo-flight">
          {airlineName === 'Super Deal' ? (
            <div className="super-deal-flag d-flex">
              Bargain <span className="super-deal-flag-holder">Deal</span>
            </div>
          ) : (
            <Image
              alt="airlineLogo"
              filename={
                airlineLogo ||
                'https://flights.travelopod.com//images/airlinelogo/default_logo.png'
              }
              type="airline-logo"
            />
          )}
        </div>
        <div className="flights-city">
          <span className="flights-cities origin">
            {isFromTo ? originIATA : origin}
          </span>
          <span className="flights-cities"> to </span>
          <span className="flights-cities destination">
            {isFromTo ? destinationIATA : destination}
          </span>
          {isDatesVisible && (
            <div className="date-flight">
              <span className="flights-dates">{startDate}</span>
              <span>&shy; – &shy;</span>
              <span className="flights-dates">{endDate}</span>
            </div>
          )}
          <div className="airlineName-flight">
            <span>{airlineName}</span>
          </div>
        </div>
      </div>
      <div className="d-flex">
        {width > 600 && (
          <span className="flights-price">
            from {getCurrency()}
            {price}
          </span>
        )}
        <button
          className="flight-deals-button ibe-search"
          tabIndex="0"
          onClick={onSearch}
        >
          <span className="button-text-default">Select</span>
          <span className="button-text-mobile">
            from {getCurrency()}
            {price}
          </span>
        </button>
      </div>
    </div>
  );
};

export default FlightCard;

FlightCard.propTypes = {
  airlineLogo: PropTypes.string.isRequired,
  airlineName: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  origin: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  startDate: PropTypes.string.isRequired
};

FlightCard.defaultProps = {
  airlineLogo: 'https://www.travelopod.com/images/all-airline-logo/CZ.png',
  airlineName: 'Airline',
  endDate: 'Feb 20',
  isSuperDeal: true,
  price: 654,
  startDate: 'Feb 10'
};
